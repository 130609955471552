import { Box } from "@mui/material";
// import SubHeader from "./SubHeader";
import { getColor } from "@/utils/style";
import {useEffect, useRef, useState} from "react";
// import Carousel from "./Carousel";
import Slider from "./Slider";
// import SelectMagazine from './SelectMagazine';
import {selectedIndexState} from '@/atoms/selectedIndexState';
import {useRecoilState} from 'recoil';
import CategorySidebar from '@/components/Landing/CategorySidebar';

export default function LandingPage({ placeholderImages }: { placeholderImages: { base64: string }[] }) {
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexState);

  const mainWrapper= useRef<HTMLDivElement>(null);
  const [mainHeight, setMainHeight] = useState(0);


    useEffect(() => {
        requestAnimationFrame(() => {
            if (!mainWrapper.current) return;
            setMainHeight(mainWrapper.current.clientHeight);
        });
    }, [mainWrapper.current]);


    return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" bgcolor={getColor("ivory")}
    ref={mainWrapper}
    >
      {/*<SubHeader selectedIndex={selectedIndex} setSelectedIndex={(index) => setSelectedIndex(index)} />*/}
      {/*<Carousel*/}
      {/*  selectedIndex={selectedIndex}*/}
      {/*  setSelectedIndex={(index) => setSelectedIndex(isNaN(index) ? 0 : index)}*/}
      {/*  placeholderImages={placeholderImages}*/}
      {/*/>*/}
        <CategorySidebar />
        <Slider
         selectedIndex={selectedIndex}
         setSelectedIndex={(index) => setSelectedIndex(isNaN(index) ? 0 : index)}
         placeholderImages={placeholderImages}
         mainrefHeight = {mainHeight}
        />
    </Box>
  );
}
