// @flow
import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

import {useWebzinesQuery} from "@/queries/webzines";
import {useElementSize} from "usehooks-ts";
import {useTranslation} from "react-i18next";
import {useRouter} from "next/router";
import Image from "next/image";
import {getColor} from '@/utils/style';

const MAX_WIDTH = 550;
const MAX_HEIGHT = 710;
// const MAX_WIDTH = 750;
// const MAX_HEIGHT = 970;
const aspect = MAX_WIDTH / MAX_HEIGHT;
const transition = "all 0.7s cubic-bezier(0.23, 1, 0.32, 1)";


const Slider = ({
                    selectedIndex,
                    setSelectedIndex,
                    placeholderImages,
                    mainrefHeight
                }: {
    selectedIndex?: number;
    setSelectedIndex?: (index: number) => void;
    placeholderImages: { base64: string }[];
    mainrefHeight: number;
}) => {
    const slider = useRef<Flicking>(null);
    const router = useRouter();
    const {
        i18n: {language},
    } = useTranslation();
    const {data: webzines} = useWebzinesQuery();
    const data = webzines?.data ?? [];
    const index = selectedIndex ?? 0;

    // 현재 화면 mobile인지 확인
    const isSm = useMediaQuery((theme) => (theme as any).breakpoints.down("sm"), {noSsr: true});

    const [sizeRef, {width, height}] = useElementSize();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const marginY = useMemo(() => 70 * (isSm ? 0.65 : 1), [isSm]);
    const CARD_HEIGHT = useMemo(
        () => Math.min(Math.min(height - marginY, width - 64 * (isSm ? 0.65 : 1)), MAX_HEIGHT),
        [width, height, marginY, MAX_HEIGHT]
    );
    const CARD_WIDTH = useMemo(() => Math.min(Math.round(CARD_HEIGHT * aspect), MAX_HEIGHT), [CARD_HEIGHT]);
    const percent = 100;
    const GAP = useMemo(() => Math.round(CARD_WIDTH * 0.06), [CARD_HEIGHT]);


    const sliderItemCount = useMemo(() => {
        if (isSm) {
            return 3;
        } else {
            const maxItems = Math.floor(width / (CARD_WIDTH + GAP));
            return Math.max(3, Math.min(maxItems, data.length)) + 4;  // 최소 3개, 최대 data.length까지
        }
    }, [isSm, width, CARD_WIDTH, data.length]);

    const [centerIndex, setCenterIndex] = useState(selectedIndex);
    const [isReady, setIsReady] = useState(false);

    const generateSize = useCallback(
        (percent: number = 1) => {
            return {
                width: Math.round(CARD_WIDTH * percent),
                height: Math.round(CARD_HEIGHT * percent),
                maxWidth: Math.round(CARD_WIDTH * percent),
                maxHeight: Math.round(CARD_HEIGHT * percent),
                cursor: "pointer",
            };
        },
        [CARD_WIDTH, CARD_HEIGHT]
    );



    useEffect(() => {
        if (slider.current && selectedIndex !== undefined){
            const lastIndex = data.length - 1 - selectedIndex;
            if (lastIndex < 0) return;
            slider.current.moveTo(lastIndex, 0);
        }
    }, [slider]);

    useEffect(() => {
        if (slider.current && selectedIndex !== centerIndex) {
            const reverseIndex = data.length - 1 - index;
            slider.current.moveTo(reverseIndex, 300);
            setCenterIndex(selectedIndex);
        };

    }, [selectedIndex, setSelectedIndex, setCenterIndex]);

    const handleReady = () => {
        setIsReady(true); // 슬라이더 준비가 완료되면 isReady 상태를 true로 변경
    };

    // console.log(GAP, 'gap')

    return (
        <Box width="100%" height="100%" flex={1} display="flex" ref={sizeRef} >
            {!isReady &&
                <Box width="20%" height="10%" position={'absolute'} left='40%' top='45%' textAlign={'center'}
                    zIndex={9999}
                >
                    <Typography fontSize="1.8rem" fontWeight="500" color={getColor("marineBlue")}>
                        Loading...
                    </Typography>
                </Box>
            }
            <Box display="flex" width="100%" height="100%" alignItems="center" overflow="hidden"
                 ref={wrapperRef}
            >
                <Box
                    width="100%"
                    height="100%"
                    maxHeight={`${CARD_HEIGHT}px`}
                    position="relative"
                    overflow="hidden"
                    boxSizing="content-box"

                >
                    <Box
                        width="100%"
                        height="100%"
                        maxHeight={`${CARD_HEIGHT}px`}
                        position="absolute"
                        top="0"
                        left="0"
                        display="flex"
                        alignItems="end"
                        justifyContent="center"
                        visibility={isReady ? "visible" : "hidden"}

                    >

                            <Flicking
                                ref={slider}
                                align="center"
                                panelsPerView={sliderItemCount}
                                circular={false}
                                onReady={handleReady}
                                onMoveEnd={e => {
                                    if (slider.current) {
                                        requestAnimationFrame(() => {
                                            if (!slider.current) return;
                                            const panels = document.querySelectorAll('.panel');
                                            const index =  panels.length - 1 - slider.current.control.activeIndex;
                                            setCenterIndex(index);
                                            setSelectedIndex?.(index);
                                        })
                                    }
                                }}
                            >

                                        {data.map((webzine, index) => {
                                            const reverseIndex = data.length - 1 - index;
                                            const orderByCenter = Math.abs(index - Math.floor(data.length / 2));
                                            const percent = Math.max(1 - 0.13 * orderByCenter, 0);
                                            return (
                                                <Box
                                                    className={`panel ${reverseIndex === selectedIndex ? 'active' : ''}`}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: `${CARD_HEIGHT}px`,
                                                    }}
                                                    key={reverseIndex}
                                                    onClick={() => {
                                                        if (!slider.current || !data) {
                                                            console.error("Slider or data is not available.");
                                                            return;
                                                        }
                                                        if (reverseIndex === selectedIndex) {
                                                            const id = data.at(reverseIndex)?._id;
                                                            if (!id) {
                                                                console.error("ID is not available.");
                                                                return;
                                                            }
                                                            router.push(`/viewer/${id}`);
                                                        } else if (index) {
                                                            setSelectedIndex?.(reverseIndex);
                                                        }
                                                    }}
                                                >
                                                    <Image
                                                        width={MAX_WIDTH}
                                                        height={MAX_HEIGHT}
                                                        style={{
                                                            transition,
                                                            ...generateSize(0.8),
                                                            transform: `perspective(600px) rotateY(${
                                                                selectedIndex === undefined ? 0 :
                                                                    reverseIndex < selectedIndex ? -30 :
                                                                        reverseIndex > selectedIndex ? 30 : 0
                                                            }deg) scale(${
                                                                reverseIndex === selectedIndex ? 1.2 : 0.8
                                                            })
                                                            translateX(${
                                                                isSm && reverseIndex === selectedIndex! -1 ? +110 :
                                                                isSm && reverseIndex === selectedIndex! + 1 ? -110 :
                                                                // sliderItemCount === 9 ? 
                                                                // // 로직 정리 필요함
                                                                // reverseIndex === selectedIndex! -1 ? +80 :
                                                                // reverseIndex === selectedIndex! + 1 ? -80 :
                                                                // 0
                                                                // :
                                                                // reverseIndex === selectedIndex! -1 ? +110 :
                                                                // reverseIndex === selectedIndex! + 1 ? -110 :
                                                                // reverseIndex === selectedIndex! -2 ? +20 :
                                                                // reverseIndex === selectedIndex! +2 ? -20 : 0
                                                                0
                                                            }px
                                                            )
                                                            `,
                                                            zIndex: Math.max(data.length - Math.abs(reverseIndex - selectedIndex!), 0),
                                                        }}
                                                        src={
                                                            (data[reverseIndex]?.url
                                                                ? (data[reverseIndex]?.thumbnail as string)
                                                                : (data[reverseIndex]?.thumbnail as Record<string, string>)?.[language]) ?? ""
                                                        }
                                                        draggable={false}
                                                        alt={`${data[reverseIndex]?.title ?? data[reverseIndex]?.publishedDate} cover image`}
                                                        // priority
                                                        placeholder="blur"
                                                        blurDataURL={placeholderImages[index].base64}
                                                        sizes={`(max-width: 768px) 100vw, ${MAX_WIDTH}px`}
                                                        loading='lazy'
                                                    />
                                                </Box>
                                            )
                                        })}
                            </Flicking>


                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Slider;


