import React, {useState, useEffect, ComponentProps} from 'react';
import Link from 'next/link';
import {Typography, useMediaQuery, Box, styled} from '@mui/material';
import {useRouter} from 'next/router';
import {useTranslation} from 'react-i18next';
import {getColor} from '@/utils/style';

type Props = {};



const CategorySidebar = (props: Props) => {
    const router = useRouter();
    const mdMatch = useMediaQuery((theme) => (theme as any).breakpoints.down("md"), {noSsr: true});
    const {
        asPath,
        query: {id},
    } = useRouter();
    const {
        i18n: {language},
    } = useTranslation();
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const shouldShowSidebar = (router.pathname === '/' || router.pathname.startsWith('/viewer')) && !mdMatch;




    const handleSidebarClick = () => {
        setIsOpen(!isOpen);
    };

    const LinkTypography = ({children, isActive, ...props}: ComponentProps<typeof Link> & { isActive: boolean }) => {
        return (
            <Link
                {...props}
                style={{
                    margin: '1.5rem 0 1.5rem 15px',
                }}
            >
                <Typography
                    fontSize="1.8rem"
                    fontWeight={isActive ? 300 : 300}
                    color={isActive ? "marineBlue" : "black"}
                    sx={{fontWeight: isActive ? 300 : 300}}
                >
                    {children}
                </Typography>
            </Link>
        );
    };


    return (
        <>
            {shouldShowSidebar &&
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="fixed"
                    width="50px"
                    top="0"
                    right="0"
                    bottom="0"
                    bgcolor="transparent"
                    zIndex="1000"

                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="55%"
                        // bgcolor="#1d1b1b"
                        width={isOpen ? '800px' : '100%'}
                        sx={{
                            transition: 'width 0.3s ease-in-out',
                            backgroundColor: isOpen ? getColor("marineBlue") : "#E6EAEE",
                    }}
                        onClick={handleSidebarClick}

                    >
                        <Box
                            sx={{transform: 'rotate(-90deg)',
                                width: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                        }}
                            color="#fff"
                            whiteSpace="nowrap"
                        >
                            <Typography
                                fontSize="1.8rem"
                                fontWeight={300}
                                color={isOpen ? "#fff" : "#000"}
                                sx={{margin: '0 0 0 0'}}
                            >
                                {language === 'es' ? 'CATEGORÍA' : 'CATEGORY'}
                            </Typography>

                        </Box>
                        {isOpen &&
                            <Box
                                bgcolor="#fff"
                                width="400px"
                                height="100%"
                                flexDirection="column"
                                display="flex"
                                justifyContent="center"
                            >
                                <LinkTypography
                                    href="/articles/event"
                                    isActive={asPath === "/articles/event"}
                                >
                                    {t("event")}
                                </LinkTypography>
                                <LinkTypography href="/articles/local-news"
                                                isActive={asPath === "/articles/local-news"}>
                                    {t("local-news")}
                                </LinkTypography>
                                <LinkTypography href="/articles/hyundai-heritage"
                                                isActive={asPath === "/articles/hyundai-heritage"}>
                                    {t("hyundai-heritage")}
                                </LinkTypography>
                                <LinkTypography href="/articles/life-style"
                                                isActive={asPath === "/articles/life-style"}>
                                    {t("life-style")}
                                </LinkTypography>
                                <LinkTypography href="/articles/brand-and-h--tech"
                                                isActive={asPath === "/articles/brand-and-h--tech"}>
                                    {t("brand-h-tech")}
                                </LinkTypography>
                                <LinkTypography href="/articles/training" isActive={asPath === "/articles/training"}>
                                    {t("training")}
                                </LinkTypography>
                                <LinkTypography href="/articles/wrc" isActive={asPath === "/articles/wrc"}>
                                    {t("wrc")}
                                </LinkTypography>
                            </Box>}

                    </Box>
                </Box>
            }
        </>

    );
};

export default CategorySidebar;
